<div class="units">
    <button
        type="button"
        class="no-padding button-image-content button-scale"
        mat-button
        [attr.aria-label]="'Add ' + unit.name"
        [matTooltip]="'Add ' + unit.name"
        *ngFor="let unit of units"
        (click)="startTimer(unit, 'enemy')"
    >
        <svg-icon
            [src]="'/assets/squad/' + unit.code + '.svg'"
            aria-hidden="true"
            svgClass="squad-icon enemy"></svg-icon>
    </button>
</div>
<div class="timers">
    <div class="timer mat-elevation-z1" *ngFor="let timer of timers; let i = index">
        <button
            type="button"
            class="unit-icon no-padding button-image-content button-scale"
            mat-button
            [attr.aria-label]="'Change side of ' + timer.unit.name + '. Currently ' + timer.side"
            [matTooltip]="'Change side of ' + timer.unit.name"
            (click)="changeSide(i)"
        >
            <svg-icon [src]="'/assets/squad/' + timer.unit.code + '.svg'"
                aria-hidden="true"
                [svgClass]="['squad-icon', timer.side]"></svg-icon>
        </button>
        <div class="time-container">
            <button
                type="button"
                class="time-action"
                mat-icon-button
                aria-label="Subtract one minute"
                matTooltip="Subtract one minute"
                (click)="addMinutesToTimer(i, -1)"
            >
                <mat-icon>remove_circle_outline</mat-icon>
            </button>
            <app-countdown [date]="timer.spawnsOn"
                [updateObservable]="updateObservable"></app-countdown>
            <button
                type="button"
                class="time-action"
                mat-icon-button
                aria-label="Add one minute"
                matTooltip="Add one minute"
                (click)="addMinutesToTimer(i, 1)"
            >
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
        <div class="actions">
            <button
                type="button"
                mat-icon-button
                aria-label="Delete timer"
                matTooltip="Delete timer"
                (click)="removeTimer(i)"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</div>
<p *ngIf="timers.length === 0" class="timers-info text-center">
    Click on a vehicle when it is destroyed and a timer will be started.
</p>
<mat-expansion-panel class="share-panel" hideToggle>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span *ngIf="roomUrl === null">
                Share your timers
            </span>
            <span
                *ngIf="roomUrl !== null"
                class="share-panel-title-desktop"
            >
                Sharing timers
            </span>
            <span
                *ngIf="roomUrl !== null"
                class="share-panel-title-mobile"
                [class.text-error]="socketStatus === 'disconnected'"
                [class.text-success]="socketStatus === 'connected'"
                [class.text-warning]="socketStatus === 'connecting'"
            >
                Sharing timers
            </span>
        </mat-panel-title>
        <mat-panel-description>
            <span *ngIf="roomUrl !== null">
                {{roomName}}:
                <span
                    [class.text-error]="socketStatus === 'disconnected'"
                    [class.text-success]="socketStatus === 'connected'"
                    [class.text-warning]="socketStatus === 'connecting'"
                >
                    {{socketStatus}}
                </span>
            </span>
        </mat-panel-description>
        <mat-icon class="expand" inline>expand_more</mat-icon>
    </mat-expansion-panel-header>
    <ng-container *ngIf="roomName === null">
        <p>
            Sharing your timers enables crews or even teams to collaborate on the status of enemy and
            friendly equipment.
        </p>
        <p>
            In a crew share scenario, every member of the crew can see and modify the timers without a
            need for radio communication.
            This enables the least busy crew member to mark destroyed vehicles.
        </p>
        <p>
            In a team or clan share scenario, anti-tank infantry can report destroyed vehicles without
            the need to relay messages through squad leads and each armor crew no longer needs to track
            respawn time of vehicles individually.
        </p>
    </ng-container>
    <div *ngIf="roomName !== null && roomUrl !== null">
        <p>
            Currently
            <span
                [class.text-error]="socketStatus === 'disconnected'"
                [class.text-success]="socketStatus === 'connected'"
                [class.text-warning]="socketStatus === 'connecting'"
            >
                {{socketStatus}}
            </span>
            <span *ngIf="socketStatus !== 'disconnected'">
                to
            </span>
            <span *ngIf="socketStatus === 'disconnected'">
                from
            </span>
            {{roomName}}.
        </p>
        <p>
            Share the room link to allow others to see, update, add, and remove timers.
        </p>
        <div class="url-container">
            <span>{{roomUrl}}</span>
            <button class="link"
                type="button"
                mat-icon-button
                [cdkCopyToClipboard]="roomUrl"
                (cdkCopyToClipboardCopied)="roomUrlCopyResult($event, roomUrlTooltip)"
                matTooltip="Copy URL"
                #roomUrlTooltip="matTooltip"
            >
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    </div>
    <div class="actions">
        <button
            type="button"
            *ngIf="roomName === null"
            mat-flat-button
            color="primary"
            (click)="share()"
        >
            <mat-spinner matButtonIcon *ngIf="socket != null" [diameter]="18"></mat-spinner>
            Share your timers
        </button>
        <button
            type="button"
            *ngIf="roomName !== null"
            mat-button
            (click)="disconnect()"
            matTooltip="Disconnect from the room">
            <mat-icon>power_off</mat-icon>
            Disconnect
        </button>
    </div>
</mat-expansion-panel>
