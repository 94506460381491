<app-header class="header"></app-header>
<div class="router-container">
    <router-outlet class="router-flex"></router-outlet>
</div>
<mat-toolbar class="footer-navigation">
    <a
        mat-button
        routerLink="/"
        [class.active]="isTimerPageActive"
        [attr.aria-current]="isTimerPageActive ? 'page' : 'false'"
    >
        <mat-icon>timer</mat-icon>
        <span class="label">Timer</span>
    </a>
    <a
        mat-button
        routerLink="about"
        routerLinkActive="active"
        [ariaCurrentWhenActive]="'page'"
    >
        <mat-icon>info</mat-icon>
        <span class="label">About</span>
    </a>
</mat-toolbar>
