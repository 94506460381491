<mat-toolbar>
    <a
        mat-button
        routerLink="/"
        class="logo button-image-content"
        matTooltip="Go to home"
        aria-label="Go to home"
    >
        <img ngSrc="/assets/logo.png" alt="" width="340" height="150">
    </a>
    <span class="flex-spacer"></span>
    <a
        mat-button
        class="github-link-text-button"
        href="https://github.com/MatthiasKunnen/squad-timer"
        rel="noopener nofollow"
        aria-label="GitHub repository"
        matTooltip="View on GitHub">
        <img
            ngSrc="/assets/icons/github-circle-transparent.svg"
            matButtonIcon
            class="github-logo"
            alt="GitHub logo"
            aria-hidden="true"
            height="20"
            width="20"
        />
        GitHub
    </a>
    <a
        mat-icon-button
        class="github-link-icon-button"
        href="https://github.com/MatthiasKunnen/squad-timer"
        rel="noopener nofollow"
        aria-label="GitHub repository"
        matTooltip="View on GitHub"
    >
        <img
            ngSrc="/assets/icons/github-circle-transparent.svg"
            matButtonIcon
            class="github-logo"
            alt="GitHub logo"
            aria-hidden="true"
            height="20"
            width="20"
        />
    </a>
</mat-toolbar>
