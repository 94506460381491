<h1>About</h1>
<p>
    The Squad Timer is developed on
    <a class="link" href="https://github.com/MatthiasKunnen/squad-timer">GitHub</a>.
</p>

<h2>Built on open source software</h2>
<p>
    The timer is made possible thanks to open source software. View the software it is built on as
    well as their license here:
    <a class="link" href="/3rdpartylicenses.txt" target="_blank">3rdpartylicenses.txt</a>.
</p>
